<template>
  <div class="view-checkout-confirm__incentive loyalty-incentive">
    <h2
      class="loyalty-incentive__title"
      v-html="$t('resto.confirm_loyalty_incentive_title', { name })"
    ></h2>

    <p
      class="loyalty-incentive__description"
      v-html="$t('resto.confirm_loyalty_incentive_points_summary')"
      v-if="hasPointsRedemption"
    ></p>

    <p
      class="loyalty-incentive__description"
      v-html="$t('resto.confirm_loyalty_incentive_vouchers_summary')"
      v-if="hasVouchersRedemption"
    ></p>

    <p
      class="loyalty-incentive__description"
      v-html="$t('resto.confirm_loyalty_incentive_referral_summary')"
      v-if="hasReferral"
    ></p>

    <actions-button
      :appearance="$pepper.Appearance.DEFAULT"
      class="loyalty-incentive__action"
      icon-post="open"
      :intent="$pepper.Intent.DANGER"
      :size="$pepper.Size.S"
      @click="goToLoyalty"
    >{{ $t('resto.go_to_loyalty_page') }}</actions-button>
  </div>
</template>

<script>
export default {
  name: 'ConfirmLoyaltyIncentive',

  inject: [
    '$loyalty',
    '$referral',
    '$user',
  ],

  computed: {
    hasPointsRedemption() {
      return this.loyalty.allowPointsRedemption || false
    },

    hasReferral() {
      return !this.$basil.isNil(this.referral) && this.referral.active === true
    },

    hasVouchersRedemption() {
      return [
        this.loyalty.allowVouchersRedemption,
        this.loyalty.redeemableVouchers.length > 0,
      ].filter(r => r === false).length === 0
    },

    loyalty() {
      return this.$basil.get(this.$loyalty, 'program', null)
    },

    name() {
      return this.$basil.get(this.loyalty, 'name', null)
      // return `<strong>${this.$basil.get(this.loyalty, 'name', null)}</strong>`
    },

    referral() {
      return this.$basil.get(this.$referral, 'program')
    },

    symbol() {
      return this.$basil.get(this.loyalty, 'points.symbol', null)
    },
  },

  methods: {
    goToLoyalty() {
      this.$router.push({ name: 'sayl-front-user.balance' })
    }
  }
}
</script>

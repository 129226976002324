<template>
  <div class="view-checkout-confirm__claims claims">
    <h2 class="claims__title">{{ $t('resto.order_has_point_to_claim', { amount, symbol }) }}</h2>

    <p
      class="claims__description"
      v-html="$t('resto.claim_points_intructions')"
    ></p>

    <actions-button
      :appearance="$pepper.Appearance.DEFAULT"
      class="claims__action"
      icon-post="open"
      :intent="$pepper.Intent.DANGER"
      :href="claimUrl"
      :size="$pepper.Size.S"
      target="_blank"
    >{{ $t('resto.go_to_claim_page') }}</actions-button>
  </div>
</template>

<script>
import Config from '@/config'

export default {
  name: 'ConfirmLoyalty',

  inject: [
    '$loyalty'
  ],

  props: {
    order: {
      type: Object,
      required: true,
    }
  },

  computed: {
    allocation() {
      return this.order.credits?.allocation ?? 0
    },

    amount() {
      let ret = this.order.credits.allocation

      if(!this.$basil.isNil(ret)) {
        let int = parseInt(ret, 10)
        let float = (ret - int).toFixed(2)
        if(float > 0 && float < 1) {
          ret = parseFloat(ret, 10).toFixed(2)
        } else {
          ret = parseInt(ret, 10)
        }
      }

      return ret
    },

    loyalty() {
      return this.$basil.get(this.$loyalty, 'program', null)
    },

    symbol() {
      return this.$basil.get(this.loyalty, 'points.symbol', null)
    },

    claimUrl() {
      return `${ Config.claimPageUrl }loyalty/${ this.order.id }/claim`
    }
  },
}
</script>

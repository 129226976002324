<template>
  <!-- Success -->
  <section class="view-checkout-confirm__order order">
    <article class="order__item order__intro">
      <h1 class="order__title">{{ $t('resto.checkout_confirm_title', { service: service }) }}</h1>
      <h2 class="order__subtitle">{{ $t('resto.checkout_confirm_cart_title') }}</h2>

      <!-- Code -->
      <dl class="order__term order__code">
        <dt>{{ $t('resto.checkout_confirm_code') }}</dt>
        <dd>{{ orderCode }}</dd>
      </dl>

      <!-- Table -->
      <dl
        class="order__term order__table"
        v-if="isEatin">
        <dt>{{ $t('resto.table_number') }}</dt>
        <dd>{{ tableNumber }}</dd>
      </dl>

      <!-- Payment method -->
      <dl
        class="order__term order__pm"
        v-if="paymentMethod">
        <dt>{{ $t('resto.checkout_confirm_pm') }}</dt>
        <dd>{{ paymentMethod }}</dd>
      </dl>

      <!-- Comment -->
      <dl
        class="order__term order__comment"
        v-if="comment">
        <dt>{{ $t('resto.checkout_review_comment') }}</dt>
        <dd>{{ comment }}</dd>
      </dl>
    </article>

    <!-- Cart -->
    <article class="order__item order__cart">
      <cart />
    </article>

    <!-- Reward -->
    <article
      class="order__item order__loyalty"
      v-if="hasLoyaltyReward">
      <div class="order__loyalty-badge">
        <ui-icon
          class="order__loyalty-icon"
          glyph="gift"/>

        <div class="order__loyalty-label">{{ $t('resto.confirm_loyalty_reward', { points: loyaltyReward }) }}</div>
      </div>
    </article>

    <!-- Pickup -->
    <article
      class="order__item order__pickup"
      v-if="isPickup">
      <div class="row">
        <h2 class="order__title">{{ service }}</h2>
      </div>

      <div class="group">
        <!-- Collect Location -->
        <div class="order__location">
          <div class="order__label">{{ $t('resto.pickup_address') }}</div>

          <address
            class="order__address"
            v-html="pickupAddress"
          ></address>

          <!-- Phone -->
          <div
            class="row"
            v-if="phone">
            <actions-button
              appearance="subtle"
              class="order__cta"
              :href="phoneLink"
              icon-pre="phone"
              :size="$pepper.Size.S"
            >{{ phone }}</actions-button>
          </div>

        </div>

        <!-- Collect Time -->
        <div class="order__time">
          <div class="order__label">{{ $t('resto.pickup_time') }}</div>

          <span
            class="order__value"
            v-if="timeSlotsAreDisabled"
          >{{ $t('resto.undefined_pickup_date_label') }}</span>

          <span
            class="order__value"
            v-else-if="isAsap"
          >{{ $t('resto.pickup_asap_label', { value: valueTime }) }}</span>

          <time
            class="order__value"
            :datetime="pickupDateISO"
            v-else
          >{{ pickupDateString }}</time>
        </div>
      </div>
    </article>

    <!-- Delivery -->
    <article
      class="order__item order__delivery"
      v-if="isDelivery">
      <div class="row">
        <h2 class="order__title">{{ service }}</h2>
      </div>

      <div class="group">
        <!-- Collect Location -->
        <div class="order__location">
          <div class="order__label">{{ $t('resto.ship_to') }}</div>

          <address
            class="order__address"
            v-html="deliveryAddress"
          ></address>
        </div>

        <!-- Delivery Time -->
        <div class="order__time">
          <div class="order__label">{{ $t('resto.estimated_delivery') }}</div>

          <span
            class="order__value"
            v-if="timeSlotsAreDisabled"
          >{{ $t('resto.undefined_pickup_date_label') }}</span>

          <span
            class="order__value"
            v-else-if="isAsap"
          >{{ $t('resto.pickup_asap_label') }}</span>

          <time
            class="order__value"
            :datetime="pickupDateISO"
            v-else
          >{{ pickupDateString }}</time>

          <span class="order__label">{{ deliveryMethod }}</span>
        </div>
      </div>
    </article>

    <!-- Virtual:: Maybe add a polling every 5s if necessary -->
    <article 
      class="order__item order__virtual"
      v-if="isVirtual && vouchersList.length > 0">
      <p class="order__virtual-title">{{ $t('resto.confirm_generated_vouchers') }}</p> 

      <div class="order__voucher" v-for="v in vouchersList">
        <p class="order__label">{{ v.name }}</p> 
        <p class="order__expiration">{{ $t('resto.voucher_to_date', { date: v.expiration }) }}</p>
      </div>
    </article>
  </section>
</template>

<script>
import { mapState } from 'vuex'

import MixinServices from '../../mixins/services'
import MixinMultishop from '@/helpers/multishop'

import Cart from '@/modules/checkout/components/cart/cart'

export default {
  name: 'ConfirmCart',

  components: {
    Cart,
  },

  inject: [
    '$checkout',
    '$core',
    '$embed',
    '$localStorage',
    '$loyalty',
    '$user',
  ],

  mixins: [
    MixinServices,
    MixinMultishop,
  ],

  data() {
    return {
      vouchers: []
    }
  },

  computed: {
    ...mapState({
      order: state => state['sayl-front.checkout'].order,
      eatin: state => state['sayl-front.service-eatin'],
    }),

    comment() {
      return this.$basil.get(this.order, 'comment')
    },

    deliveryMethod() {
      return this.order && this.order.delivery_method && this.order.delivery_method.name + ' (+' + this.$n(this.order.delivery_method.price, 'currency') + ')'
    },

    deliveryAddress() {
      let ret = ''

      if(this.order && this.order.delivery_address) {
        ret = `${this.order.delivery_address.number}, ${this.order.delivery_address.street} <br/>`
        ret += `${this.order.delivery_address.zip} ${this.order.delivery_address.city} ${this.order.delivery_address.country}`
      }

      return ret
    },

    deliveryAddressLink() {
      return `https://maps.google.com/?q=${this.deliveryAddress}`
    },

    isAsap() {
      return this.$basil.get(this.order, 'pickup_asap', false)
    },

    isModeTt() {
      return this.$localStorage.mode === 'tt' && this.$localStorage.service === 'eatin';
    },

    hasLoyaltyReward() {
      let ret = this.$basil.get(this.order, 'credits.reward', null);
      return !this.$basil.isNil(ret)
    },

    rewardSymbol() {
      return this.$basil.get(this.$loyalty, 'program.points.symbol', null)
    },

    loyaltyReward() {
      let reward = this.$basil.get(this.order, 'credits.reward', null)

      if(!this.$basil.isNil(reward)) {
        let int = parseInt(reward, 10)
        let float = (reward - int).toFixed(2)
        if(float > 0 && float < 1) {
          reward = parseFloat(reward, 10).toFixed(2)
        } else {
          reward = parseInt(reward, 10)
        }
      }

      return reward + this.rewardSymbol
    },

    orderTimeslot() {
      return this.$basil.get(this.order, 'timeslot', null)
    },

    paymentMethod() {
      let ret = null
      let slug = this.$basil.get(this.order, 'payment_method')

      if (!this.$basil.isNil(slug)) {
        ret = this.$t(`resto.payment_methods_${slug}`)
      }

      return ret
    },

    phone() {
      let flpos = this.$basil.get(this.shop, 'flposes', []).find(flpos => flpos.id === this.order.fulfillment_location_id)
      return this.$basil.get(flpos, 'phone')
    },

    pickupAddress() {
      let flpos = this.$basil.get(this.shop, 'flposes', []).find(flpos => flpos.id === this.order.fulfillment_location_id)

      let ret = this.$basil.get(flpos, 'name')
      let address = this.$basil.get(flpos, 'address')

      if (address){
        ret += `<br>${address}`
      }

      return ret
    },

    pickupAddressLink() {
      return `https://maps.google.com/?q=${this.pickupAddress}`
    },

    pickupDateObject() {
      let ret = null

      if(!this.$basil.isNil(this.orderTimeslot)) {
        let ts = this.$basil.get(this.orderTimeslot, 'timestamp')
        let tz = this.$basil.get(this.orderTimeslot, 'timezone.offset')
        let v = new Date(((ts + tz) + (new Date().getTimezoneOffset() * 60)) * 1000)
        ret = v
      }

      return ret
    },

    valueTime() {
      let ret = null
      if(!this.$basil.isNil(this.orderTimeslot)) {
        let ts = this.$basil.get(this.orderTimeslot, 'timestamp')
        let tz = this.$basil.get(this.orderTimeslot, 'timezone.offset')
        let v = new Date(((ts + tz) + (new Date().getTimezoneOffset() * 60)) * 1000)

        let hours = v.getHours()
        if(hours < 10) {
          hours = '0' + hours
        }

        let minutes = v.getMinutes()
        if(minutes < 10) {
          minutes = '0' + minutes
        }

        ret = hours + ':' + minutes
      }
      return ret
    },

    pickupDate() {
      let ret = this.pickupDateObject
      return ret ? this.$date(ret) : null
    },

    pickupDateISO() {
      let ret = this.pickupDateObject
      return ret ? ret.toISOString() : null
    },

    pickupDateString() {
      let ret = this.pickupDateObject
      return this.$date(ret, 'long')
    },

    service() {
      let n = this.serviceName === 'pickup' ? 'base' : this.serviceName
      return this.$t(`resto.service_${n}`)
    },

    shop() {
      return this.$basil.get(this.$embed, 'shop.model')
    },

    tableNumber() {
      let ret = ''

      if(this.isEatin) {
        const tableId = this.$localStorage.table
        const t = this.tables.find(t => t.id === tableId)

        if(t) {
          ret = t.name || ''
        }
      }

      return ret
    },

    timeSlotsAreDisabled() {
      return this.$basil.get(this.order, 'timeslots_disabled', false)
    },

    orderCode() {
      return this.$basil.get(this.order, 'pickup_code', '[DEV] - CODE')
    },

    phoneLink() {
      return `tel://${this.phone}`
    },

    shop() {
      return this.$basil.get(this.$embed, 'shop.model')
    },

    tables() {
      let ret = []
      if(this.eatin) {
        ret = this.eatin.tables
      }
      return ret
    },

    vouchersList() {
      if(!this.isVirtual || this.vouchers.length === 0) {
        return []
      }

      return this.vouchers.map((v) => {
        return {
          name: v.name,
          expiration: v.to ? this.$basil.i18n.date(new Date(v.to)) : null
        }
      })
    }
  },

  mounted() {
    if(this.order && this.order.service === 'virtual') {
      this.$checkout.getVouchers({ hash: this.$route.params.id })
        .then((v) => this.vouchers = v)
        .catch((e) => $console.error(e))
    }
  }
}
</script>

<template>
  <div class="cart-product__body">
    <div
      class="cart-product__bundle"
      :key="j"
      v-for="(group, j) in groups">
      <!-- Variant -->
      <div class="cart-product__bundle-name">{{ group.variant_title }}</div>

      <!-- Options -->
      <ul
        class="cart-product__options"
        v-if="hasOptions(j)">
        <li
          class="cart-product__option"
          :key="i"
          v-for="(option, i) in options(j)">
          <span class="cart-product__option-name">{{ option.locale }}</span>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import MixinCurrency from '@/helpers/currency'

export default {
  name: 'CartProductBundle',

  mixins: [ MixinCurrency, ],

  props: {
    item: {
      type: Object,
      required: true
    }
  },

  computed: {
    groups() {
      return this.$basil.get(this.item, 'bundle', [])
    },
  },

  methods: {
    hasOptions(index) {
      return this.options(index).length > 0
    },

    options(index) {
      return this.$basil.get(this.groups[index], 'options', [])
    },
  },
}
</script>

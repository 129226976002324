<template>
  <main :class="classes">
    <div
      class="view-checkout-confirm__container container"
      :class="{ '-block': !hasSecondCol }">
      <actions-button
        :appearance="$pepper.Appearance.SUBTLE"
        :disabled="loading"
        :href="backHref"
        icon-pre="arrow-left"
      >{{ $t('resto.back_button') }}</actions-button>

      <div
        v-if="loading"
        class="container__loader">
        <ui-loader />
      </div>

      <!-- Error -->
      <section
        class="view-checkout-confirm__order"
        v-if="isFailed && !loading">
        <!-- 404 -->
        <article class="view-checkout-confirm__item">
          <h1 class="view-checkout-confirm__title">{{$t('resto.checkout_failed_title')}}</h1>
          <h2 class="view-checkout-confirm__subtitle">{{ $t('resto.checkout_failed_cart_title') }}</h2>
        </article>

        <article class="view-checkout-confirm__item flow">
          <p class="view-checkout-confirm__description">{{ $t('resto.checkout_failed_message') }}</p>

          <actions-button
            :href="backHref"
            icon-pre="arrow-left"
          >{{ $t('resto.back_button') }}</actions-button>
        </article>
      </section>

      <div
        :class="{ '-cols': hasSecondCol }"
        v-if="!isFailed && !loading">
        <div class="col">
          <confirm-order />
        </div>

        <div
          class="col flow"
          v-if="hasSecondCol">
          <claims
            :order="order"
            v-if="hasAllocation"
          />

          <loyalty-incentive v-if="hasLoyalty" />

          <newsletter
            :user="user"
            v-if="!optinAlreadyAccepted"
          />

          <socials
            :shop="shop"
            v-if="hasSocials"
          />
        </div>
      </div>
    </div>
  </main>
</template>

<script>
import { mapState } from 'vuex'

import ConfirmOrder from '../components/confirm/order'
import Claims from '../components/confirm/claims'
import LoyaltyIncentive from '../components/confirm/loyalty-incentive'
import Newsletter from '../components/confirm/newsletter'
import Socials from '../components/confirm/socials'

import MixinMultishop from '@/helpers/multishop'

export default {
  name: 'CheckoutConfirm',

  components: {
    ConfirmOrder,
    Claims,
    Newsletter,
    LoyaltyIncentive,
    Socials,
  },

  inject: [
    '$checkout',
    '$core',
    '$embed',
    '$localStorage',
    '$loyalty',
    '$user',
  ],

  mixins: [ MixinMultishop, ],

  data() {
    return {
      loading: true,
      error: null,
      errors: {},
      signup: false
    }
  },

  computed: {
    ...mapState({
      order: state => state['sayl-front.checkout'].order,
    }),

    backHref() {
      return {
        name: this.isMultiShop ? 'sayl-front-catalog.shops' : 'sayl-front-catalog.catalog',
        params: {
          embed: this.$route.params.embed,
          service: this.$route.query.service
        }
      }
    },

    classes() {
      return {
        'view-checkout-confirm__body': true,
      }
    },

    hasAllocation() {
      return this.allocation > 0 && this.hasLoyalty
    },

    hasLoyalty() {
      let excludedShopIds = this.$basil.get(this.$loyalty, 'program.excludedShopIds', [])
      let isShopExcluded = false
      
      if(!this.$basil.isNil(excludedShopIds) && !this.$basil.isEmpty(excludedShopIds)) {
        isShopExcluded = !this.$basil.isNil(excludedShopIds.find(e => e === this.$basil.get(this.$embed, 'shop.model.id')))
      }

      return [
        !this.$basil.isNil(this.$basil.get(this.$loyalty, 'program', null)),
        !this.isModeTt,
        !isShopExcluded
      ].filter(r => r === false).length === 0
    },

    hasSecondCol() {
      return [
        this.hasAllocation,
        this.hasLoyalty,
        !this.optinAlreadyAccepted,
        this.hasSocials,
      ].find(r => r === true) != null
    },

    hasSocials() {
      return this.shop.social.length > 0 && !this.isModeTt
    },

    isFailed() {
      return this.$basil.get(this.order, 'isFailed', true)
    },

    isModeTt() {
      return this.$localStorage.mode === 'tt' && this.$localStorage.service === 'eatin';
    },

    optinAlreadyAccepted() {
      return this.$basil.get(this.user, 'optIn', false)
    },

    shop() {
      return this.$basil.get(this.$embed, 'shop.model')
    },

    user() {
      return this.$basil.get(this.$user, 'user')
    },

    embed() {
      return this.$basil.get(this.$embed, 'embed.model')
    },
  },

  methods: {
    resetUser() {
      if(!this.user.isLogged || this.isModeTt) {
        return this.$user.reset()
      } else {
        return Promise.resolve()
      }
    }
  },

  mounted() {
    this.loading = true
    this.$bus.$emit('injekt.sp.confirm')

    // No confirmation id
    if(this.$basil.isNil(this.$route.params.id)) {
      this.$router.push({name: 'sayl-front-catalog.catalog'}).catch(() => {})
      return
    }

    this.$localStorage.cart = null
    this.$localStorage['cart-service'] = null

    this.$checkout.view({ orderId: this.$route.params.id })
      .then(() => this.resetUser())
      .then(() => this.$core.initCart({
        fresh: true,
        service: this.order.service,
        enrich: this.isMultiShops ? 1 : 0
      }))
      .catch((error) => {
        this.error = error
        $console.error(error)
      })
      .finally(() => {
        this.loading = false
      })
  }
}
</script>

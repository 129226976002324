<template>
  <div :class="classes">
    <!-- Body:: When shops and no suborders -->
    <div
      class="cart__body"
      v-if="hasItems && hasShops && !loading && !(subOrders && order.sub_orders)">
      <article
        class="cart__shops"
        :key="shop.id"
        v-for="(shop) in orderedShops">
        <div class="cart__subheader">
          <p class="cart__shop">{{ shop.name }}</p>
        </div>

        <ul
          class="cart__items">
          <cart-product
            class="cart__item"
            :item="item"
            :key="i"
            v-for="(item, i) in orderShops[shop.id]"
          />
        </ul>
      </article>
    </div>

    <!-- Body:: When suborders -->
    <div
      class="cart__body"
      v-if="hasItems && subOrders && order.sub_orders && !loading">
      <article
        class="cart__shops"
        :key="suborder.shop"
        v-for="(suborder) in order.sub_orders">
        <div class="cart__subheader">
          <p class="cart__shop">{{ suborder.shop_name }}</p>

          <data-tag
            class="cart__substatus"
            :size="$pepper.Size.S"
            v-if="suborder.order_status != null"
          >{{ $t('resto.order_status_' + suborder.order_status) }}</data-tag>
        </div>

        <ul class="cart__items">
          <cart-product
            class="cart__item"
            :item="item"
            :key="i"
            v-for="(item, i) in suborder.order_items"
          />
        </ul>
      </article>
    </div>

    <!-- Body:: Default view -->
    <div
      class="cart__body"
      v-if="hasItems && !hasShops">
      <ul class="cart__items">
        <cart-product
          class="cart__item"
          :item="item"
          :key="i"
          v-for="(item, i) in items"
        />
      </ul>
    </div>

    <div
      class="cart__body"
      v-if="!hasItems">
      <p class="cart__empty">{{ $t('resto.cart_empty') }}</p>
    </div>

    <!-- Footer (total) -->
    <footer
      class="cart__footer"
      v-if="hasItems">

      <ul class="cart__items">
        <li
          class="cart__item cart-fee"
          :class="{ 
            '-interactive': item.hasOwnProperty('subs') && item.hasOwnProperty('showSubs') && $basil.get(item, 'subs.length', 0) > 1,
            '-open': item.hasOwnProperty('showSubs') ? subItems[item.showSubs] : false
          }"
          :key="i"
          v-for="(item, i) in fees"
          @click="onShowSub(item)">
          <div class="cart-fee__labels -item">
            <div class="cart-fee__label">{{ item.label }}</div>
            <div class="cart-fee__label">{{ item.value }}</div>
          </div>

          <ul 
            class="cart__subitems" 
            v-if="item.subs && subItems[item.showSubs]">
            <li
              class="cart__subitem cart-fee"
              :key="i"
              v-for="(s, i) in item.subs">
              <div class="cart-fee__labels">
                <div class="cart-fee__label">{{ s.label }}</div>
                <div class="cart-fee__label">{{ s.value }}</div>
              </div>
            </li>
          </ul>
        </li>

        <li class="cart__item cart-fee -total">
          <div class="cart-fee__labels -item">
            <div class="cart-fee__label">{{ $t('resto.cart_footer_total') }}</div>
            <div class="cart-fee__label">{{ toCurrency(total) }}</div>
          </div>
        </li>
      </ul>
    </footer>
  </div>
</template>

<script>
import { mapState } from 'vuex'

import CartProduct from './cart-product'
import MixinCurrency from '@/helpers/currency'

import MixinMultishop from '@/helpers/multishop'

export default {
  name: 'CheckoutCart',

  props: {
    showVat: {
      type: Boolean,
      default: false,
    },

    subOrders: {
      type: Boolean,
      default: false,
    },

    value: {}
  },

  mixins: [
    MixinCurrency,
    MixinMultishop,
  ],

  inject: [
    '$checkout',
    '$embed',
  ],

  components: { CartProduct, },

  data() {
    return {
      loading: true, 
      subItems: {
        tiers: false
      },
    }
  },

  computed: {
    ...mapState({
      service: state => state['sayl-front.service'].service,
      item: state => state['sayl-front.checkout'].order,
      paymentMethod: state => state['sayl-front.checkout'].paymentMethod
    }),

    order() {
      return this.value ? this.value : this.item
    },

    orderedShops() {
      return this.subshops && this.order && this.subshops.filter((s) => {
        return this.order.shops[s.id] != null && this.order.shops[s.id].length > 0
      })
    },

    classes() {
      return {
        'cart': true
      }
    },

    isDelivery() {
      return this.$basil.get(this.order, 'service') === 'delivery'
    },

    items() {
      return this.$basil.get(this.order, 'order_items', [])
    },

    credits() {
      return this.$basil.get(this.order, 'credits.money', null)
    },

    hasCredits() {
      return !this.$basil.isNil(this.credits)
    },

    fees() {
      let ret = []

      if(this.showVat) {
        ret.push({
          label: this.$t('resto.cart_footer_vat'),
          value: this.toCurrency(this.vat)
        })
      }

      if(this.isDelivery) {
        let delivery = this.$basil.get(this.order, 'delivery_method.price', 0)
        if(delivery > 0) {
          ret.push({
            label: this.$t('resto.delivery_fee'),
            value: this.toCurrency(delivery)
          })
        }
      }

      if(this.orderServiceFees) {
        ret.push({
          label: this.$t('resto.service_fee'),
          value: this.toCurrency(this.orderServiceFees)
        });
      }

      if(this.orderTransactionFees) {
        ret.push({
          label: this.$t('resto.transaction_fee'),
          value: this.toCurrency(this.orderTransactionFees)
        });
      }

      if(this.serviceFees) {
        ret.push(this.serviceFees)
      }

      if(!!this.voucher) {
        ret.push({
          label: this.$t('resto.cart_footer_voucher'),
          value: this.toCurrency(this.voucher)
        })
      }

      if(!!this.trigger) {
        ret.push({
          label: this.$t('resto.cart_footer_web3_trigger'),
          value: this.toCurrency(this.trigger)
        })
      }

      if(this.hasCredits) {
        ret.push({
          label: this.$t('resto.cart_footer_fidelity'),
          value: this.toCurrency((-1) * this.credits)
        })
      }

      if(!!this.tier) {
        let totals = [
          {
            label: this.$t('resto.cart_footer_tier_fixed'),
            value: this.$basil.get(this.tier, 'fixed.value', 0) || 0,
          },
          {
            label: this.$t('resto.cart_footer_tier_percent'),
            value: this.$basil.get(this.tier, 'percent.amount', 0) || 0, 
          },
        ].filter(s => s.value > 0)
        
        let total = totals.reduce((acc, cur) => acc + cur.value, 0)

        ret.push({
          label: this.$t('resto.cart_footer_tier', { tier: this.tier.tier_name }),
          value: this.toCurrency((-1) * total.toFixed(2)),
          subs: totals.length > 1 ? totals.map(t => {
            return {
              label: t.label,
              value: this.toCurrency((-1) * t.value.toFixed(2)),
            }
          }) : null,
          showSubs: 'tiers'
        })
      }

      return ret
    },

    hasItems() {
      return this.items && this.items.length > 0
    },

    hasShops() {
      return this.isMultiShop && this.order && Object.keys(this.order?.shops ?? {}).length > 0
    },

    orderServiceFees() {
      return this.$basil.get(this.order, 'fees.service', 0) || 0
    },

    orderShops() {
      return this.order && this.order.shops ? this.order.shops : []
    },

    orderTransactionFees() {
      return this.$basil.get(this.order, 'fees.transaction', 0) || 0
    },

    selectedServiceFees() {
      let fees = this.$basil.get(this.$checkout, '_fees', []) || []

      let rule = null

      if(!fees.length) {
        return null
      }

      if(!this.paymentMethod) {
        rule = [
          rule = fees.find((rule) => rule.payment_mode === null && rule.service === this.serviceName),
          rule = fees.find((rule) => rule.payment_mode === null && rule.service === null)
        ];

        return rule.find(r => !this.$basil.isNil(r))
      }

      rule = [
        fees.find((rule) => rule.service === this.serviceName && rule.payment_mode === this.paymentMethodSlug),
        fees.find((rule) => rule.service === this.serviceName && rule.payment_mode === null),
        fees.find((rule) => rule.service === null && rule.payment_mode === this.paymentMethodSlug),
        fees.find((rule) => rule.service === null && rule.payment_mode === null),
      ]

      return rule.find(r => !this.$basil.isNil(r))
    },

    serviceFees() {
      return this.selectedServiceFees
        ? {
          label: this.$t('resto.service_fee'),
          value: this.toCurrency(this.$basil.get(this.selectedServiceFees, 'fees', 0))
        } : null
    },

    serviceName() {
      return this.$basil.get(this.service, 'apiname', '') || ''
    },

    paymentMethodSlug() {
      return this.$basil.get(this.paymentMethod, 'slug', '') || ''
    },

    total() {
      return this.$basil.get(this.order, 'price_total', 0) + this.$basil.get(this.selectedServiceFees, 'fees', 0)
    },

    vat() {
      return this.$basil.get(this.order, 'vat_total', 0)
    },

    voucher() {
      return this.$basil.get(this.order, 'voucher.amount_discount')
    },

    tier() {
      return this.$basil.get(this.order, 'loyalty_tier_discount', null)
    },

    trigger() {
      return this.$basil.get(this.order, 'web3_trigger.discount') * -1
    }
  },

  methods: {
    onShowSub(item) {
      if(!item.subs || !item.showSubs) {
        return
      }

      this.subItems[item.showSubs] = !this.subItems[item.showSubs]
    }
  },

  async mounted() {
    if(this.isMultiShop && !this.subshops) {
      this.loading = true
      let shops = await this.$embed.shop.getShops({ childrenOnly: 1 })
      this.$store.commit('sayl-front.catalog.multishop/setShops', shops)
    }
    this.loading = false
  }
}
</script>

<template>
  <!-- Newsletters -->
  <section class="view-checkout-confirm__newsletter newsletter">
    <!-- Header -->
    <header class="newsletter__header">
      <h2 class="newsletter__title">{{ $t('resto.checkout_confirm_socials_title') }}</h2>
    </header>

    <div class="newsletter__body">
      <!-- Newsletter -->
      <div class="newsletter__newsletter">
        <!-- Newsletter::Form -->
        <form
          class="newsletter__form flow"
          v-if="!signup">
          <forms-input
            class="newsletter__input"
            :placeholder="$t('resto.email_placeholder')"
            :errors="errors['email']"
            v-model="email"
          >{{ $t('resto.checkout_confirm_socials_description') }}</forms-input>

          <actions-button
            :appearance="$pepper.Appearance.PRIMARY"
            class="newsletter__cta"
            @click.stop.prevent="onSignup"
          >{{ $t('resto.button_signup') }}</actions-button>
        </form>

        <!-- Newsletter::Confirm -->
        <div v-if="signup">
          <span class="newsletter__signup">{{ $t('resto.signup_thanks') }}</span>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: 'ConfirmNewletter',

  inject: [
    '$user'
  ],

  props: {
    user: {},
  },

  data() {
    return {
      errors: {},
      email: null,
      emailPattern: /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/,
      optin: true,
      signup: false,
    }
  },

  methods: {
    onSignup() {
      this.errors = {}

      if(!this.emailPattern.test(this.email) || this.email == null){
        this.errors['email'] = [this.$t('resto.email_not_valid')]
        return
      }

      this.user.email = this.email
      this.user.optIn = true

      this.$user.update({})
        .then((u) => {
          this.$emit('signup')
          this.signup = true
        })
        .catch((error) => $console.error(error))
    },
  }
}
</script>

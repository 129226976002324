import { mapState } from 'vuex'

export default {
  ...mapState({
    order: state => state['sayl-front.checkout'].order
  }),

  computed: {
    isDelivery() {
      return this.serviceName === 'delivery'
    },
  
    isEatin() {
      return this.serviceName === 'eatin'
    },
  
    isPickup() {
      return this.serviceName === 'pickup'
    },

    isVirtual() {
      return this.serviceName === 'virtual'
    },

    serviceName(){
      return this.$basil.get(this.order, 'service')
    }
  }

}

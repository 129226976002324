<template>
  <!-- Social -->
  <section class="view-checkout-confirm__social social">
    <!-- Header -->
    <header class="social__header">
      <h2 class="social__title">{{ $t('resto.checkout_confirm_follow_us_title') }}</h2>
    </header>

    <!-- Social media -->
    <div class="social__content">
      <ul class="social__list">
        <li
          class="social__item"
          :key="item.type"
          v-for="item in socials">
          <a
            class="social__link"
            :href="item.href"
            rel="noopener noreferrer"
            target="_blank"
            :title="item.label">
            <ui-img
              :alt="item.label"
              class="social__logo"
              :src="item.icon"
            />

            <div class="social__label">{{ item.label }}</div>
          </a>
        </li>
      </ul>
    </div>
  </section>
</template>

<script>
import UiImg from '@/components/ui/img'

export default {
  name: 'ConfirmSocials',

  components: {
    UiImg
  },

  inject: [ '$localStorage', ],

  props: {
    shop: {},
  },

  computed: {
    socials() {
      let ret = []

      if(this.shop.social) {
        ret = this.shop.social
        ret.forEach( s => {
          s.icon = `https://cdn-apps.sayl.cloud/askemma/statics/images/icons/social/${s.type}.svg`
          s.label = this.$t(`resto.social_${s.type}_cta`)
        })
      }

      return ret
    },
  },
}
</script>

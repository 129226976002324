<template>
  <div class="cart-product__body">
    <ul
      class="cart-product__options"
      v-if="hasOptions">
      <li
        class="cart-product__option"
        :key="i"
        v-for="(option, i) in options"
      >{{ option.toString }}</li>
    </ul>
  </div>
</template>

<script>
import MixinCurrency from '@/helpers/currency'

export default {
  name: 'CartProductDefault',

  mixins: [ MixinCurrency, ],

  props: {
    item: {
      type: Object,
      required: true
    }
  },

  computed: {
    hasOptions() {
      return this.options.length > 0
    },

    hasVariants() {
      return this.$basil.get(this.item, 'variant_id') != null
    },

    options() {
      return this.$basil.get(this.item, 'options', [])
    },

    variant() {
      return this.$basil.get(this.item, 'variant_title')
    }
  },
}
</script>

<template>
  <li :class="classes">
    <aside class="cart-product__aside">
      <output class="cart-product__qty">{{ item.quantity }}x</output>
    </aside>

    <div class="cart-product__content">
      <!-- Header -->
      <header class="cart-product__header">
        <!-- Name -->
        <div class="cart-product__title">{{ item.title }}</div>

        <!-- Price -->
        <div class="cart-product__price">
          <span>{{ toCurrency(item.total_price) }}</span>
        </div>
      </header>

      <!-- Body -->
      <component
        :is="productType"
        :item="item"
      />
    </div>
  </li>
</template>

<script>
import MixinCurrency from '@/helpers/currency'

import CartProductDefault from './cart-product-default'
import CartProductBundle from './cart-product-bundle'
import CartProductGiftVoucher from './cart-product-gift-voucher'

export default {
  name: 'CartProduct',

  components: {
    CartProductBundle,
    CartProductDefault,
    CartProductGiftVoucher
  },

  mixins: [ MixinCurrency, ],

  props: {
    item: {
      type: Object,
      required: true
    }
  },

  data() {
    return {
      debounce: null,
      enabled: true
    }
  },

  computed: {
    classes() {
      return {
        'cart-product': true,
        '-default': this.isProductDefault === true,
        '-bundle': this.isProductBundle === true
      }
    },

    isProductBundle() {
      return this.$basil.get(this.item, 'type', 'default') === 'bundle'
    },

    isProductDefault() {
      return this.$basil.get(this.item, 'type', 'default') === 'default'
    },

    productType() {
      let ret = 'cart-product-default'

      switch(this.$basil.get(this.item, 'type', 'default')) {
        case 'bundle':
          ret = 'cart-product-bundle'
          break
        case 'gift_voucher':
          ret = 'cart-product-gift-voucher'
          break
      }

      return ret
    },
  },

  methods: {
    itemPrice(item) {
      return this.toCurrency(item.total.price.value)
    },

    hasPromoPrice(item) {
      return !!item.total.promo
    },

    itemPromoPrice(item) {
      return this.toCurrency(item.total.promo.value)
    },
  }
}
</script>
